import { Create, Datagrid, DateField, Edit, List, SimpleForm, TextField, TextInput, useAuthenticated } from "react-admin";


export const LicenseSetupList = () => {
    useAuthenticated();
    return (
        <List title="Gerenciador de Licenças / Lista de Ambientes Provisionados">
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="host" label="Host"/>
                <TextField source="database" label="Banco de Dados"/>
                <TextField source="user" label="Usuário"/>
                <TextField source="license" label="Licença"/>
                <DateField source="created_at" label="Criado em"/>
                <DateField source="updated_at" label="Atualizado em"/>
            </Datagrid>
        </List>
    )
};

export const LicenseSetupCreate = () => {
    useAuthenticated();
    return (
        <Create title="Gerenciador de Licenças / Cadastro de Ambiente">
            <SimpleForm>
                <TextInput source="host" label="Host"/>
                <TextInput source="database" label="Banco de Dados"/>
                <TextInput source="user" label="Usuário"/>
                <TextInput source="license" label="Licença"/>
            </SimpleForm>
        </Create>
    )
};


export const LicenseSetupEdit = () => {
    useAuthenticated();
    return (
        <Edit 
            title="Gerenciador de Licenças / Editar Ambiente"
        >
            <SimpleForm>
                <TextInput source="id" disabled/>
                <TextInput source="host" label="Host"/>
                <TextInput source="database" label="Banco de Dados" />
                <TextInput source="user" label="Usuário"/>
                <TextInput source="license" label="Licença"/>
            </SimpleForm>
        </Edit>
    )
};
