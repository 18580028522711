// in src/MyLoginPage.js
import { useState } from 'react';
import { useLogin, useNotify, Login, SimpleForm, TextInput, useSafeSetState } from 'react-admin';
import { Button, CircularProgress } from '@mui/material';

const LoginPage = ({ theme }: any) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useSafeSetState(false);
    const login = useLogin();
    const notify = useNotify();

    const handleSubmit = (e:any) => {
        setLoading(true);
        e.preventDefault();

        login({ email, password }).then(() => setLoading(false)).catch(() => {
            setLoading(false);
            notify('Invalid email or password')
        });
    };

    const children = (
        <SimpleForm onSubmit={handleSubmit} toolbar={false}>
            <TextInput
                source="email"
                type="email"
                value={email}
                onChange={e => setEmail(e.target.value)}
                fullWidth
            />
            <TextInput
                source="password"
                type="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
                fullWidth
            />
            <Button
                title='ENTRAR'
                variant="contained"
                type="submit"
                color="primary"
                disabled={loading}
                onClick={(e) => handleSubmit(e)}
                fullWidth
            >
                {loading ? (
                    <CircularProgress
                        size={19}
                        thickness={3}
                    />
                ) : (
                    'ENTRAR'
                )}
            </Button>
        </SimpleForm>
    );

    return (
        <Login
            children={children}
        />
    );
};

export default LoginPage;