import { fetchUtils } from "react-admin";
import { cookies } from "./cookies";

export const fetchJson = (url: string, options = {} as any) => {
  let jwt = cookies.get('auth');
  options.user = {
    authenticated: true,
    // use the token from cookies
    token: `Bearer ${jwt}`
  };
  return fetchUtils.fetchJson(url, options);
};
