import { Admin, Resource, Layout, memoryStore } from "react-admin";
import env from "../core/enviroment";
import jsonServerProvider from "ra-data-json-server";
import NotFound from "../components/NotFound";
import Dashboard from "../components/Dashboard";
import AppBar from "../components/AppBar";
import {UserList, UserCreate, UserEdit, UserEditPassword} from "../components/Users"
import LoginPage from "../components/Login"
import authProvider from "../core/authProvider"
import { fetchJson } from "../utils/fetchJson";
import { LicenseSetupCreate, LicenseSetupEdit, LicenseSetupList } from "../components/LicenseSetup";

const dataProvider = jsonServerProvider(env.SERVER_API_URL, fetchJson);

const MyLayout = (props:any) => <Layout sx={{ backgroundColor: "#E3E8ED" }}{...props} appBar={AppBar} />;

const App = () => (
  <Admin disableTelemetry
    dataProvider={dataProvider}
    title="Gerenciador de Licenças /"
    loginPage={LoginPage}
    dashboard={Dashboard}
    catchAll={NotFound}
    layout={MyLayout} 
    store={memoryStore()}
    authProvider={authProvider}
    requireAuth
    >
    <Resource 
      name="user"
      list={UserList} 
      create={UserCreate} 
      edit={UserEdit} 
      show={UserEditPassword}
      hasShow
    />
    <Resource 
      name="license/setup"
      list={LicenseSetupList} 
      create={LicenseSetupCreate} 
      edit={LicenseSetupEdit} 
      hasShow />
  </Admin>
);

export default App;