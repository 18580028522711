import * as React from "react";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Title, useAuthenticated } from 'react-admin';

const Dashboard = () => {
    useAuthenticated();
    return (
        <Card
            sx={{ mt: 2 }}
            >
            <Title title="Gerenciador de Licenças /" />
            <CardContent>
                <p>Lorem ipsum dolor sit amet</p>
                <p>Lorem ipsum dolor sit amet</p>
                <p>Lorem ipsum dolor sit amet</p>
            </CardContent>
        </Card>
    );
}
export default Dashboard;