import { AuthProvider } from "react-admin";
import env from "../core/enviroment";
import { Token } from "../models/token";
import { cookies, setExpireInMinutes } from "../utils/cookies";

const authProvider: AuthProvider = {
    login: ({ email, password }) =>  {
        const request = new Request(`${env.SERVER_API_URL}/auth/login`, {
            method: 'POST',
            body: JSON.stringify({ email, password }),
            headers: new Headers({ 'Content-Type': 'application/json' }),
        });
        return fetch(request)
            .then(response => {
                if (response.status < 200 || response.status >= 300) {
                    throw new Error(response.statusText);
                }
                return response.json();
            })
            .then( ( auth: Token ) => {
                cookies.set('auth', auth.access_token, { expires: setExpireInMinutes(auth.expires_in) });
            })
            .catch(() => {
                throw new Error('Network error')
            });
    },
    checkError: (error) => {
        const status = error.status;
        if (status === 401 || status === 403) {
            cookies.remove('auth');
            return Promise.reject({ message: false, redirectTo: '/login' });
        }
        // other error code (404, 500, etc): no need to log out
        return Promise.resolve();
    },
    checkAuth: () => cookies.get('auth')
    ? Promise.resolve()
    : Promise.reject(),
    logout: () => {
        cookies.remove('auth');
        return Promise.resolve();
    },
    getIdentity: () => {
        try {
            const auth = cookies.get('auth');
            const { id, fullName, avatar } = auth;
            return Promise.resolve({ id, fullName, avatar });
        } catch (error) {
            return Promise.reject(error);
        }
    },
    getPermissions: () => {
        // Required for the authentication to work
        return Promise.resolve();
    },
    changePassword: (userId: Number, { old_password, new_password, confirm_password }: any) => {
        const auth = cookies.get('auth');
        if(auth) {
            const request = new Request(`${env.SERVER_API_URL}/user/${userId}/change-password`, {
                method: 'POST',
                body: JSON.stringify({ old_password, new_password, confirm_password }),
                headers: new Headers({ 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${auth}`
                }),
            });
            return fetch(request)
                .then(response => {
                    if (response.status < 200 || response.status >= 300) {
                        throw new Error(response.statusText);
                    }
                    return { redirectTo: '/user' };
                })
                .catch(() => {
                    throw new Error('Network error')
                });
        }
        throw new Error('User not authenticated!')
    }
};

export default authProvider;