import { AppBar, ToggleThemeButton } from 'react-admin';

const MyAppBar = () => (
    <AppBar
        sx={{
            backgroundColor: '#1A364C',
        }}
        // toolbar={<ToggleThemeButton />} 
    />
);

export default MyAppBar;