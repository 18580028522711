/* eslint-disable react-hooks/rules-of-hooks */
import { 
    Datagrid, 
    DateField, 
    EmailField, 
    List, 
    TextField,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    useAuthenticated,
    ReferenceArrayInput,
    SelectArrayInput,
    SimpleShowLayout,
    Show,
    TopToolbar,
    ShowButton,
    useNotify,
    useRedirect,
} from 'react-admin';
import PasswordIcon from '@mui/icons-material/Password';
import { useState } from 'react';
import authProvider from '../core/authProvider';
// import { CircularProgress } from '@mui/material';

const choices = [
    { id: 0, name: 'Inativo' },
    { id: 1, name: 'Ativo' },
]

export const UserList = () => {
    useAuthenticated();
    return (
        <List title="Gerenciador de Licenças / Lista de Usuários">
            <Datagrid rowClick="edit">
                <TextField source="id" />
                <TextField source="role.role" label="Perfil"/>
                <TextField source="name" label="Nome"/>
                <EmailField source="email" />
                <TextField source="is_active" label="Status"/>
                <DateField source="created_at" label="Criado em"/>
                <DateField source="updated_at" label="Atualizado em"/>
            </Datagrid>
        </List>
    )
};

export const UserCreate = () => {
    useAuthenticated();
    return (
        <Create title="Gerenciador de Licenças / Cadastro de Usuário">
            <SimpleForm>
                <ReferenceArrayInput reference="role" source="role">
                    <SelectArrayInput optionText="role" label="Perfil" />
                </ReferenceArrayInput>
                <TextInput source="name" label="Nome"/>
                <TextInput source="email" />
                <TextInput source="password" label="Senha"/>
            </SimpleForm>
        </Create>
    )
};


export const UserEdit = () => {
    useAuthenticated();
    const ChangePasswordButton = () => (
        <TopToolbar>
            <ShowButton 
                label="Trocar Senha" 
                icon={<PasswordIcon />}
            />
        </TopToolbar>
    );
    return (
        <Edit 
            title="Gerenciador de Licenças / Editar Usuário"
            actions={<ChangePasswordButton />}
        >
            <SimpleForm>
                <TextInput source="id" disabled/>
                <TextInput source="role.role" label="Perfil" disabled/>
                <TextInput source="name" />
                <TextInput source="email" />
                <SelectArrayInput source="is_active" label="Status" choices={choices}/>
            </SimpleForm>
        </Edit>
    )
};

export const UserEditPassword = () => {
    useAuthenticated();
    const [old_password, setOld_password] = useState('');
    const [new_password, setNew_password] = useState('');
    const [confirm_password, setConfirm_password] = useState('');
    // const [loading, setLoading] = useSafeSetState(false);
    const redirect = useRedirect();
    const notify = useNotify();

    // const SavePasswordButton = () => (
    //     <SaveButton>
    //         {loading ? (
    //             <CircularProgress
    //                 size={19}
    //                 thickness={3}
    //             />
    //         ) : (
    //             'ENTRAR'
    //         )}
    //     </SaveButton>
    // );

    const handleSubmit = (e:any) => {
        // setLoading(true);
        // e.preventDefault();
        authProvider.changePassword( e.id, { old_password, new_password, confirm_password })
        .then(() => {
            // setLoading(false);
            notify('Success!');
        }).catch((e: any) => {
            // setLoading(false);
            notify('Invalid password');
            redirect('user');
            console.log('error >>>>', e);
        });
    };
    return (
        <Show 
            title="Gerenciador de Licenças / Trocar de Senha"
        >
            <SimpleShowLayout >
                <SimpleForm onSubmit={handleSubmit}>
                    <TextInput source="old_password"
                        type="password"
                        value={old_password}
                        onChange={e => setOld_password(e.target.value)}
                        label="Senha atual"/>
                    <TextInput source="new_password" label="Nova Senha"
                        type="password"
                        value={new_password}
                        onChange={e => setNew_password(e.target.value)}/>
                    <TextInput source="confirm_password" label="Confirmar Nova Senha"
                        type="password"
                        value={confirm_password}
                        onChange={e => setConfirm_password(e.target.value)}/>
                </SimpleForm>
            </ SimpleShowLayout>
        </Show>
    )
};
